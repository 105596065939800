import React, { useEffect, useState } from "react";
import BottomContainer from "./BottomContainer";
import BplLiveChart from "./BplLiveChart";
import ChartVitalDisplay from "./ChartVitalDisplay";
import DialogBox from "./DialogBox";
import GraphPump from "./GraphPump";

const GraphBplNewWeb = ({ deviceid }) => {
  const [localdeviceID, setLocalDeviceID] = useState("");
  const [patientThreshold, setPatientThreshold] = useState({});
  const [hrValue, setHrValue] = useState(null);
  const [rrValue, setRrValue] = useState(null);
  const [spo2Value, setSpo2Value] = useState(null);
  const [nibpSystolic, setNibpSystolic] = useState(null);
  const [nibpDiastolic, setNibpDiastolic] = useState(null);
  const [nibpMean, setNibpMean] = useState(null);
  const [t1Value, setT1Value] = useState(null);
  const [t2Value, setT2Value] = useState(null);
  const [prValue, setPrValue] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [slide, setSlide] = useState([
    { min: 50, max: 120 },
    { min: 90, max: 100 },
    { min: 8, max: 30 },
  ]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (deviceid) {
      setLocalDeviceID(deviceid);
    } else {
      console.warn("deviceID is undefined");
    }
    const ws = new WebSocket("wss://itouch-test.iorbit.health:8083/consumer"); //TEST
    ws.onopen = () => {
      console.log("WebSocket connection opened", deviceid);
      ws.send(
        JSON.stringify({
          PacketID: "WS_DEVICE_CONSUME_REQ",
          DeviceUUID: deviceid,
          SamplingRate: 250,
          WindowSize: 10,
          MinYVal: 0,
          MaxYVal: 128,
        })
      );
    };
  }, [deviceid]);

  useEffect(() => {
    if (localdeviceID === "100924") {
      <GraphPump localdeviceID={localdeviceID} />;
    }
    const ws = new WebSocket("wss://itouch-test.iorbit.health:8083/consumer"); //TEST
    ws.onopen = () => {
      console.log("WebSocket connection opened", localdeviceID);
      ws.send(
        JSON.stringify({
          PacketID: "WS_DEVICE_CONSUME_REQ",
          DeviceUUID: localdeviceID,
          SamplingRate: 250,
          WindowSize: 10,
          MinYVal: 0,
          MaxYVal: 128,
        })
      );
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    let buffer = [];
    const THROTTLE_INTERVAL = 10000;

    const processBuffer = () => {
      if (buffer.length > 0) {
        const message = buffer.shift();
        if (message && message.valueQuantity && message.valueQuantity.value) {
          switch (message.code.text) {
            case "Heart rate":
              setHrValue(message.valueQuantity.value);
              break;
            case "Respiratory rate":
              setRrValue(message.valueQuantity.value);
              break;
            case "Oxygen saturation in Arterial blood":
              setSpo2Value(message.valueQuantity.value);
              break;
            case "Pulse rate":
              setPrValue(message.valueQuantity.value);
              break;
            case "Systolic blood pressure":
              setNibpSystolic(message.valueQuantity.value);
              break;
            case "Diastolic blood pressure":
              setNibpDiastolic(message.valueQuantity.value);
              break;
            case "Mean blood pressure":
              setNibpMean(message.valueQuantity.value);
              break;
            case "Body temperature T1":
              setT1Value(message.valueQuantity.value);
              break;
            case "Body temperature T2":
              setT2Value(message.valueQuantity.value);
              break;
            default:
              console.log("Unknown observation type:", message.code.text);
          }
        }
      }
    };
    ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      buffer.push(message);
    };

    const intervalId = setInterval(processBuffer, THROTTLE_INTERVAL);

    return () => {
      clearInterval(intervalId);
      ws.close();
    };
  }, [localdeviceID]);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("Message received:", event);
      console.log("Message origin:", event.origin);
      console.log("Message data:", event.data);

      if (
        event.type === "message" &&
        (event.origin === "" ||
          event.origin === "http://10.0.2.2:3000" ||
          event.origin === "http://localhost:3000" ||
          event.origin === "https://dev.iorbit.health" ||
          event.origin === "http://192.168.1.175:3000")
      ) {
        let data;
        if (typeof event.data === "string") {
          try {
            data = JSON.parse(event.data);
          } catch (e) {
            console.error("Invalid JSON:", e);
            return;
          }
        } else {
          data = event.data;
        }

        if (data) {
          try {
            const extractedData = JSON.parse(JSON.stringify(data));
            const localdeviceID = extractedData?.deviceid;
            const stringPatient = extractedData?.stringPatient
              ? decodeURIComponent(extractedData.stringPatient)
              : "";

            if (localdeviceID) {
              setLocalDeviceID(localdeviceID);
            }

            if (stringPatient) {
              setPatientThreshold(JSON.parse(stringPatient));
            }
          } catch (e) {
            console.error("Error processing data:", e);
          }
        }
      }
    };

    window.addEventListener("message", handleMessage, true);

    return () => {
      window.removeEventListener("message", handleMessage, true);
    };
  }, []);

  useEffect(() => {
    if (patientThreshold) {
      setSlide([
        {
          min: parseFloat(patientThreshold.hrmin) || 50,
          max: parseFloat(patientThreshold.hrmax) || 120,
        },
        {
          min: parseFloat(patientThreshold.spo2min) || 90,
          max: parseFloat(patientThreshold.spo2max) || 100,
        },
        {
          min: parseFloat(patientThreshold.rrmin) || 8,
          max: parseFloat(patientThreshold.rrmax) || 30,
        },
      ]);
    }
  }, [patientThreshold]);

  const slideInfo = [
    {
      color: "#7CFC00",
      range: { min: slide[0].min, max: slide[0].max },
      tag: "HR",
      value: "Ecg",
      unit: "bpm",
    },
    {
      color: "#009FE3",
      range: { min: slide[1].min, max: slide[1].max },
      tag: "SpO₂",
      value: "SPO2",
      unit: "%",
    },
    {
      color: "#F2B809",
      range: { min: slide[2].min, max: slide[2].max },
      tag: "RR",
      value: "RR",
      unit: "rpm",
    },
  ];

  const openDialogfunction = (index) => {
    setOpenDialog(true);
    setIndex(index);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#080f18",
        padding: "20px",
        borderRadius: "12px",
        color: "#ffffff",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "80%",
            height: "70vh",
            backgroundColor: "#1c2b38",
            borderRadius: "12px",
            padding: "20px",
          }}
        >
          <BplLiveChart parameterName="ECG" hrValue={hrValue} />
        </div>
        <div
          style={{
            width: "18%",
            height: "70vh",
            paddingLeft: "20px",
            backgroundColor: "#1c2b38",
            borderRadius: "12px",
          }}
        >
          <div
            onClick={() => openDialogfunction(0)}
            style={{
              height: "22vh",
              marginBottom: "10px",
              backgroundColor: "#12202a",
              borderRadius: "8px",
            }}
          >
            <ChartVitalDisplay
              index={0}
              colour={"#3BE041"}
              vitalvalue={hrValue}
              decodedpatient={patientThreshold}
              slideInfo={slideInfo}
              slide={slide[0]}
            />
          </div>
          <div
            onClick={() => openDialogfunction(1)}
            style={{
              height: "23vh",
              marginBottom: "10px",
              backgroundColor: "#12202a",
              borderRadius: "8px",
            }}
          >
            <ChartVitalDisplay
              index={1}
              colour={"#FF5900"}
              vitalvalue={spo2Value}
              decodedpatient={patientThreshold}
              slideInfo={slideInfo}
              slide={slide[1]}
            />
          </div>
          <div
            onClick={() => openDialogfunction(2)}
            style={{
              height: "23vh",
              backgroundColor: "#12202a",
              borderRadius: "8px",
            }}
          >
            <ChartVitalDisplay
              index={2}
              colour={"#FF5900"}
              vitalvalue={rrValue}
              decodedpatient={patientThreshold}
              slideInfo={slideInfo}
              slide={slide[2]}
            />
          </div>
        </div>
      </div>
      <BottomContainer
        nibpSystolic={nibpSystolic}
        nibpDiastolic={nibpDiastolic}
        nibpMean={nibpMean}
        t1Value={t1Value}
        t2Value={t2Value}
        prValue={prValue}
      />
      {openDialog && (
        <DialogBox
          open={openDialog}
          index={index}
          closeDialog={() => setOpenDialog(false)}
        />
      )}
    </div>
  );
};

export default GraphBplNewWeb;
