import React, { useEffect, useState } from 'react';
import DialogBox from './DialogBox';
import EcgChart from './EcgChart';
import LiveLineChart from './LiveLineChart';
import PpgChart from './PpgChart';
// import { useParams } from 'react-router-dom';
import BottomContainer from './BottomContainer';
import ChartVitalDisplay from './ChartVitalDisplay';
const GraphNew = ({ deviceid }) => {
    // const maxDataPoints = 125;
    // let { deviceid, patient } = useParams();
    const [deviceID, setDeviceID] = useState('');
    const [patientThreshold, setPatientThreshold] = useState({});

    const [ppg, setPpg] = useState(Array(620).fill(0));
    const [ecg, setEcg] = useState(Array(1250).fill(0));
    const [rrw, setRrw] = useState(Array(620).fill(0));
    const [index, setIndex] = useState(0);
    const [hrValue, setHrValue] = useState(null);
    const [rrValue, setRrValue] = useState(null);
    const [spo2Value, setSpo2Value] = useState(null);
    const [nibpSystolic, setNibpSystolic] = useState(null);
    const [nibpDiastolic, setNibpDiastolic] = useState(null);
    const [nibpMean, setNibpMean] = useState(null);
    const [t1Value, setT1Value] = useState(null);
    const [t2Value, setT2Value] = useState(null);
    const [prValue, setPrValue] = useState(null);
    // const [params, setParams] = useState({});
    const [slide, setSlide] = useState([
        { min: 50, max: 295 },
        { min: 70, max: 100 },
        { min: 8, max: 35 }
    ]);
    const [paramValues, setParamValues] = useState({







    })

    // Initial Working code
    // New working code with more conditions and Origin
    useEffect(() => {
        const handleMessage = (event) => {
            console.log('Message received:', event);
            console.log('Message origin:', event.origin);
            console.log('Message data:', event.data);

            // Ensure the message is coming from the expected origin
            if (event.type === "message" &&
                (event.origin === "" ||
                    event.origin === 'http://10.0.2.2:3000' ||
                    event.origin === 'http://localhost:3000' ||
                    event.origin === 'https://dev.iorbit.health')) {
                let data;
                if (typeof event.data === 'string') {
                    if (event.data) {
                        try {
                            data = JSON.parse(event.data);
                        } catch (e) {
                            console.error('Invalid JSON:', e);
                            return;
                        }
                    } else {
                        console.warn('Empty string received');
                        return;
                    }
                } else {
                    data = event.data;
                }

                if (data) {
                    try {
                        const extractedData = JSON.parse(JSON.stringify(data));
                        const deviceId = extractedData?.deviceid;
                        const stringPatient = extractedData?.stringPatient ? decodeURIComponent(extractedData.stringPatient) : '';

                        // Important alerts for testing
                        // alert("deviceId: " + deviceId);
                        // alert("stringPatient: " + stringPatient);

                        if (deviceId) {
                            setDeviceID(deviceId);
                        } else {
                            console.warn('deviceId is undefined');
                        }

                        if (stringPatient) {
                            setPatientThreshold(JSON.parse(stringPatient));
                        } else {
                            console.warn('stringPatient is undefined or empty');
                        }
                    } catch (e) {
                        console.error('Error processing data:', e);
                    }
                } else {
                    console.warn('Data is undefined');
                }
            } else {
                console.warn('Unexpected message origin:', event.origin);
            }
        };

        window.addEventListener('message', handleMessage, true);

        return () => {
            window.removeEventListener('message', handleMessage, true);
        };
    }, []);
    // New working code with more conditions and Origin

    useEffect(() => {
        setSlide([
            {
                min: parseFloat(patientThreshold ? patientThreshold.hrmin : 30), max: parseFloat(patientThreshold ? patientThreshold.hrmax : 295)
            },
            {
                min: parseFloat(patientThreshold ? patientThreshold.spo2min : 70), max: parseFloat(patientThreshold ? patientThreshold.spo2max : 100)
            },
            {
                min: parseFloat(patientThreshold ? patientThreshold.rrmin : 8), max: parseFloat(patientThreshold ? patientThreshold.rrmax : 35)
            }
        ]);
    }, [patientThreshold])


    useEffect(() => {
        const ws = new WebSocket("wss://itouch-test.iorbit.health:8083/consumer") //TEST
        ws.onopen = () => {
            console.log('WebSocket connection opened', deviceid);
            ws.send(JSON.stringify(
                {
                    "PacketID": "WS_DEVICE_CONSUME_REQ",
                    "DeviceUUID": deviceid,
                    // "DeviceUUID": deviceId,
                    // "DeviceUUID": "f63324200444899d",
                    "SamplingRate": 250,
                    "WindowSize": 10,
                    "MinYVal": 0,
                    "MaxYVal": 128
                }
            ));
        };


        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        let buffer = [];
        const THROTTLE_INTERVAL = 10000; // 1 minute
        const processBuffer = () => {
            if (buffer.length > 0) {
                const message = buffer.shift();
                console.log("message.code.text", message.code.text);
                if (message && message.valueQuantity && message.valueQuantity.value) {
                    switch (message.code.text) {
                        case "Heart rate":
                            console.log("HR Value:", message.valueQuantity.value);
                            setHrValue(message.valueQuantity.value);
                            break;
                        case "Respiratory rate":
                            console.log("RR Value:", message.valueQuantity.value);
                            setRrValue(message.valueQuantity.value);
                            break;
                        case "Oxygen saturation in Arterial blood":
                            console.log("SpO2 Value:", message.valueQuantity.value);
                            setSpo2Value(message.valueQuantity.value);
                            break;
                        case "Pulse rate":
                            setPrValue(message.valueQuantity.value);
                            break;
                        case "Systolic blood pressure":
                            setNibpSystolic(message.valueQuantity.value);
                            break;
                        case "Diastolic blood pressure":
                            setNibpDiastolic(message.valueQuantity.value);
                            break;
                        case "Mean blood pressure":
                            setNibpMean(message.valueQuantity.value);
                            break;
                        case "Body temperature":
                            setT1Value(message.valueQuantity.value);
                            break;
                        case "Body temperature2":
                            setT2Value(message.valueQuantity.value);
                            break;
                        default:
                            console.log("Unknown observation type:", message.code.text);
                    }
                }
            }
        };


        ws.onmessage = (e) => {
            const message = JSON.parse(e.data);
            buffer.push(message);
        };


        const intervalId = setInterval(processBuffer, THROTTLE_INTERVAL);






        ws.onmessage = async (e) => {
            // const message = event.data;
            const message = JSON.parse(e.data);
            console.log(message, "messaged");
            const { doubleArray, ParamUUID } = await processMessage(message);
            console.log(doubleArray, ParamUUID, "newdatafound");
            handleEachArray(doubleArray, ParamUUID)
        };
        const handleEachArray = (array, id) => {
            if (id === '10000') setEcg(array)
            if (id === '10002') setPpg(array)
            if (id === '10004') setRrw(array)
        }
        return () => {
            clearInterval(intervalId);
            ws.close();
        };
    }, [deviceid]);






    const processMessage = async (msgString) => {
        let ParamUUID = '';
        let doubleArray = null;
        try {
            const commandParams = msgString;
            const command = commandParams.PacketID;
            if (command === "WS_PARAM_DATA") {
                const paramUUID = commandParams.ParamUUID;
                const paramType = commandParams.ParamType;
                const dataType = commandParams.hasOwnProperty("DataType") ? commandParams["DataType"] : "Double";

                if (paramType === "Stream") {
                    ParamUUID = commandParams.ParamUUID === undefined ? 10004 : commandParams.ParamUUID;
                    const data = commandParams["Data"];
                    if (dataType === "Double") {
                        doubleArray = await convertFromBase64StringToDouble(data);
                    } else {
                        doubleArray = await convertBase64ComaSeparatedStringToDoubleArray(data);
                    }
                } else {
                    const paramValue = commandParams.Value;
                    const valD = parseFloat(paramValue);

                    // Update the correct state based on paramUUID
                    if (paramUUID === '10001') { // Heart Rate (HR)
                        setHrValue(valD);
                    } else if (paramUUID === '10003') { // SpO₂
                        setSpo2Value(valD);
                    } else if (paramUUID === '10005') { // Respiratory Rate (RR)
                        setRrValue(valD);
                    }

                    setParamValues((prevValues) => ({
                        ...prevValues,
                        [paramUUID]: {
                            paramUUID,
                            paramName: getParamName(paramUUID),
                            value: valD
                        }
                    }));
                }
            }
        } catch (error) {
            console.error("Error processing message:", error);
        }

        return { doubleArray, ParamUUID };
    };

    // Mapping UUID to Parameter Name
    const getParamName = (paramUUID) => {
        switch (paramUUID) {
            case '10001':
                return 'Heart Rate (HR)';
            case '10003':
                return 'SpO₂';
            case '10005':
                return 'Respiratory Rate (RR)';
            case '10004':
                return 'ECG';
            case '1000':
                return 'NIBP Systolic';
            case '1000':
                return 'NIBP Diastolic';
            case '10007':
                return 'NIBP Mean';
            case '10008':
                return 'Body Temperature 1 (T1)';
            case '10009':
                return 'Body Temperature 2 (T2)';
            case '10010':
                return 'Pulse Rate (PR)';
            default:
                return 'Unknown Parameter';
        }
    };

    const convertFromBase64StringToDouble = (message) => {
        const byteData = atob(message);
        const byteArray = new Uint8Array(byteData.length);
        for (let i = 0; i < byteData.length; i++) {
            byteArray[i] = byteData.charCodeAt(i);
        }

        const doubleArray = [];
        const dataView = new DataView(byteArray.buffer);
        if (byteArray.length >= 8) {
            for (let i = 0; i < byteArray.length; i += 8) {
                if (i + 8 <= byteArray.length) {
                    doubleArray.push(dataView.getFloat32(i, false)); // true for little-endian
                }
            }
        }
        return doubleArray;
    };

    const convertBase64ComaSeparatedStringToDoubleArray = (message) => {
        const byteData = atob(message);
        const intStrings = byteData.split(",");
        return intStrings.map((str) => parseInt(str));
    };








    const [openDialog, setOpenDialog] = useState(false);
    useEffect(() => {
        if (patientThreshold) {
            setSlide([
                {
                    min: parseFloat(patientThreshold.hrmin) || 30,
                    max: parseFloat(patientThreshold.hrmax) || 295
                },
                {
                    min: parseFloat(patientThreshold.spo2min) || 70,
                    max: parseFloat(patientThreshold.spo2max) || 100
                },
                {
                    min: parseFloat(patientThreshold.rrmin) || 8,
                    max: parseFloat(patientThreshold.rrmax) || 35
                }
            ]);
        }
    }, [patientThreshold]);
    console.log("slide in index", slide);
    const slideInfo = [
        {
            color: '#3BE041',
            range: {
                min: slide[0].min, max: slide[0].max
            },
            tag: 'Ecg',
            value: 'Ecg',
            unit: 'bpm'

        },
        {
            color: '#009FE3',
            range: {
                min: slide[1].min, max: slide[1].max
            },
            tag: 'SpO₂',
            value: 'SPO2',
            unit: '%'
        },
        {
            color: '#F2B809',
            range: {
                min: slide[2].min, max: slide[2].max
            },
            tag: 'RR',
            value: 'RR',
            unit: 'rpm'
        },
    ];


    console.log("Akshivparams", index);


    const openDialogfunction = (index) => {
        setOpenDialog(true);
        setIndex(index);
    };



















    return (
        <div style={{ minWidth: "130%", height: '130vh', backgroundColor: '#080f18', padding: '20px', borderRadius: '12px', color: '#ffffff' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80%', height: '100vh', backgroundColor: '#1c2b38', borderRadius: '12px', padding: '20px' }}>
                    <EcgChart liveData={ecg} parameterName='ECG' parameterId={"10000"} />
                    <PpgChart liveData={ppg} parameterName='SPO2' parameterId={"10002"} />
                    <LiveLineChart liveData={rrw} parameterName='RR' parameterId={"10004"} />
                </div>
                <div style={{ width: '21%', height: '100vh', paddingLeft: '20px', backgroundColor: '#1c2b38', borderRadius: '12px' }}>
                    <div onClick={() => openDialogfunction(0)} style={{ height: '30vh', marginBottom: '10px', backgroundColor: '#12202a', borderRadius: '8px' }}>
                        <ChartVitalDisplay
                            index={0}
                            colour={'#3BE041'}
                            vitalvalue={hrValue}  // Apply the HR value here
                            decodedpatient={patientThreshold}
                            slideInfo={slideInfo}
                            slide={slide[0]}
                        />
                    </div>
                    <div onClick={() => openDialogfunction(1)} style={{ height: '30vh', marginBottom: '10px', backgroundColor: '#12202a', borderRadius: '8px' }}>
                        <ChartVitalDisplay
                            index={1}
                            colour={'#FF5900'}
                            vitalvalue={spo2Value}  // Apply the SpO₂ value here
                            decodedpatient={patientThreshold}
                            slideInfo={slideInfo}
                            slide={slide[1]}
                        />
                    </div>
                    <div onClick={() => openDialogfunction(2)} style={{ height: '30vh', backgroundColor: '#12202a', borderRadius: '8px' }}>
                        <ChartVitalDisplay
                            index={2}
                            colour={'#FF5900'}
                            vitalvalue={rrValue}  // Apply the RR value here
                            decodedpatient={patientThreshold}
                            slideInfo={slideInfo}
                            slide={slide[2]}
                        />
                    </div>
                </div>
            </div>


            <BottomContainer nibpsys={nibpSystolic}
                nibpdia={nibpDiastolic}
                nibpmean={nibpMean}
                t1={t1Value}
                Temp2={t2Value}
                prValue={prValue}
            />
            {openDialog && <DialogBox open={openDialog} index={index} closeDialog={() => setOpenDialog(false)} />}
        </div>
    )
}

export default GraphNew;