import React, { useEffect, useState } from 'react';
import BplLiveChart from './BplLiveChart';

const GraphBplNew = () => {
    const [deviceID, setDeviceID] = useState('');
    const [patientThreshold, setPatientThreshold] = useState({});
    const [vitals, setVitals] = useState({
        hr: null,
        rr: null,
        spo2: null,
        nibpSystolic: null,
        nibpDiastolic: null,
        nibpMean: null,
        t1: null,
        t2: null,
        pr: null
    });
    const [slide, setSlide] = useState([
        { min: 50, max: 120 },
        { min: 90, max: 100 },
        { min: 8, max: 30 }
    ]);
    const [ws, setWs] = useState(null); // WebSocket state

    const RECONNECT_INTERVAL = 5000;
    const NO_DATA_TIMEOUT = 10000;

    useEffect(() => {
        const handleMessage = (event) => {
            if (
                event.type === 'message' &&
                (event.origin === '' ||
                    event.origin === 'http://10.0.2.2:3000' ||
                    event.origin === 'http://localhost:3000' ||
                    event.origin === 'https://dev.iorbit.health' ||
                    event.origin === 'http://192.168.1.175:3000' ||
                    event.origin === 'https://itouch-health.iorbit.health')) {
                let data;
                if (typeof event.data === 'string') {
                    if (event.data) {
                        try {
                            data = JSON.parse(event.data);
                        } catch (e) {
                            console.error('Invalid JSON:', e);
                            return;
                        }
                    } else {
                        console.warn('Empty string received');
                        return;
                    }
                } else {
                    data = event.data;
                }

                if (data) {
                    try {
                        const extractedData = JSON.parse(JSON.stringify(data));
                        const deviceId = extractedData?.deviceid;
                        const stringPatient = extractedData?.stringPatient
                            ? decodeURIComponent(extractedData.stringPatient)
                            : '';

                        if (deviceId) {
                            setDeviceID(deviceId);
                        } else {
                            console.warn('deviceId is undefined');
                        }

                        if (stringPatient) {
                            setPatientThreshold(JSON.parse(stringPatient));
                        } else {
                            console.warn('stringPatient is undefined or empty');
                        }
                    } catch (e) {
                        console.error('Error processing data:', e);
                    }
                } else {
                    console.warn('Data is undefined');
                }
            } else {
                console.warn('Unexpected message origin:', event.origin);
            }
        };

        window.addEventListener('message', handleMessage, true);

        return () => {
            window.removeEventListener('message', handleMessage, true);
        };
    }, []);

    useEffect(() => {
        if (patientThreshold) {
            setSlide([
                { min: patientThreshold.hrmin || 50, max: patientThreshold.hrmax || 120 },
                { min: patientThreshold.spo2min || 90, max: patientThreshold.spo2max || 100 },
                { min: patientThreshold.rrmin || 8, max: patientThreshold.rrmax || 30 }
            ]);
        }
    }, [patientThreshold]);

    useEffect(() => {
        const createWebSocket = () => {
            const wsInstance = new WebSocket('wss://itouch-test.iorbit.health:8083/consumer'); //TEST
            wsInstance.onopen = () => {
                console.log('WebSocket connection opened', deviceID);
                wsInstance.send(
                    JSON.stringify({
                        PacketID: 'WS_DEVICE_CONSUME_REQ',
                        DeviceUUID: deviceID,
                        SamplingRate: 250,
                        WindowSize: 10,
                        MinYVal: 0,
                        MaxYVal: 128
                    })
                );
            };

            wsInstance.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            const buffer = [];
            let timeoutId;

            const processBuffer = () => {
                if (buffer.length > 0) {
                    const message = buffer.shift();
                    if (message && message.valueQuantity && message.valueQuantity.value) {
                        setVitals((prevVitals) => ({
                            ...prevVitals,
                            [message.code.text.toLowerCase().replace(/\s/g, '')]: message.valueQuantity.value
                        }));
                    }
                }
            };

            const clearVitalsIfNoData = () => {
                timeoutId = setTimeout(() => {
                    console.warn('No data received in the last 10 seconds, clearing vitals');
                    setVitals({
                        hr: null,
                        rr: null,
                        spo2: null,
                        nibpSystolic: null,
                        nibpDiastolic: null,
                        nibpMean: null,
                        t1: null,
                        t2: null,
                        pr: null
                    });
                    wsInstance.close();
                }, NO_DATA_TIMEOUT);
            };

            wsInstance.onmessage = (e) => {
                const message = JSON.parse(e.data);
                buffer.push(message);
                processBuffer();
                clearTimeout(timeoutId);
                clearVitalsIfNoData();
            };

            setWs(wsInstance);
        };

        createWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [deviceID, ws]);

    return (
        <div style={{ width: '100%', height: '100vh', backgroundColor: '#080f18' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '70vh' }}>
                    <BplLiveChart parameterName='ECG' hrValue={vitals.hr} />
                </div>
            </div>
        </div>
    );
};

export default GraphBplNew;
