import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//mui-components
import { Close, Done, Home } from "@mui/icons-material";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { blue } from '@mui/material/colors';
import { emphasize, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

//externals
import { Icon } from "@iconify/react/dist/iconify.js";
import { format } from 'date-fns';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

//internals
import Iconify from '../../components/iconify/Iconify';
import Preview from "../../layout/Preview";
import DicomViewer from "./DiComViewer";

// DICOM Viewer import
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import dicomParser from "dicom-parser";

//services
import ApiCalls from "../../services/try";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

// ----------------------------------------------------------------------

const ManageDiagnostics = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

  const [patients, setPatients] = useState([]);
  const [physicians, setPhysicians] = useState([]);
  const [selectedPhysicians, setSelectedPhysicians] = useState([]);
  const [filters, setFilters] = useState({
    date: null,
    imageType: '',
    scanType: '',
  });
  const [DICOMImageDetails, setDICOMImageDetails] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogs, setOpenDialogs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [patientIdError, setPatientIdError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [imageTypeError, setImageTypeError] = useState(false);
  const [filePathError, setFilePathError] = useState(false);
  const [openPhysicianList, setOpenPhysicianList] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State for showing the alert
  const [error, setError] = useState(false);
  const [fileError, setFileError] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [imageType, setImageType] = useState('Standard');
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [patientId, setPatientId] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [scanType, setScanType] = useState('');
  const [imageDescription, setImageDescription] = useState('');
  const [bodySite, setBodySite] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [dobErrorMessage, setDobErrorMessage] = useState('')
  const [filePath, setFilePath] = useState('');
  const [savePatientId, setSavedPatientId] = useState('');
  const [alertMessage, setAlertMessage] = useState(''); // State for alert message

  const formatDate = (date) => {
    return format(new Date(date), 'MM/dd/yyyy');
  };

  const maxDate = formatDate(new Date());

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Reset specific errors related to the field being changed
    switch (name) {
      case 'patientId':
        setPatientId(value);
        setPatientIdError(false); // Reset patient ID error
        break;
      case 'name':
        setName(value);
        setNameError(false); // Reset name error
        break;
      case 'dob':
        setDob(value);
        setDobError(false); // Reset DOB error
        setDobErrorMessage(''); // Reset DOB error message
        break;
      default:
        break;
    }
  };

  const handleSaveDetails = () => {
    // Collect validation errors
    const errors = {};

    // Check if file is missing for DICOM images
    if (imageType === "DICOM" && !selectedFile) {
      errors.file = true;
      setFileError(true);
      setFileErrorMessage('Please select a DICOM file to proceed.');
    }

    // Check if file is missing for Standard images
    if (imageType === "Standard" && !selectedFile) {
      errors.file = true;
      setFileError(true);
      setFileErrorMessage('Please select a file to proceed.');
    }

    // Check if patientId is missing and should show an error for Standard images
    if (!patientId && imageType !== "DICOM") {
      errors.patientId = true;
    }

    // Check if name is missing and should show an error for Standard images
    if (!name && imageType !== "DICOM") {
      errors.name = true;
    }

    // Check if dob is missing or in the future and should show an error for Standard images
    if (!dob && imageType !== "DICOM") {
      errors.dob = true;
      setDobErrorMessage('DOB is required')
    } else if (dob && new Date(dob) > new Date()) {
      errors.dob = true;
      setDobErrorMessage('Date of Birth cannot be in the future.');
    }

    // Always check imageType
    if (!imageType) {
      errors.imageType = true;
      setError('Please select an image type.');
    }

    // Check if filePath is missing and should show an error for Standard images
    if (!filePath && imageType !== "DICOM") {
      errors.filePath = true;
    }

    // Display errors if any
    if (Object.keys(errors).length > 0) {
      // Set all errors at once
      setPatientIdError(errors.patientId || false);
      setNameError(errors.name || false);
      setDobError(errors.dob || false);
      setImageTypeError(errors.imageType || false);
      setFilePathError(errors.filePath || false);
      return; // Stop further execution
    } else {
      // Implement save details functionality here

      let tenantId = secureSessionStorage.getItem("tenantId");
      let companyId = secureSessionStorage.getItem("compId");
      let physicianId = "";
      const rolename = secureSessionStorage.getItem("rolename");

      if (rolename === "Tenant") {
        tenantId = secureSessionStorage.getItem("UUID");
        companyId = "0";
        physicianId = "0";
      } else if (rolename === "Company") {
        tenantId = secureSessionStorage.getItem("tenantId");
        companyId = secureSessionStorage.getItem("UUID");
        physicianId = "0";
      } else if (rolename === "Physician") {
        tenantId = secureSessionStorage.getItem("tenantId");
        companyId = secureSessionStorage.getItem("compId");
        physicianId = secureSessionStorage.getItem("UUID");
      }

      // Define the data to be sent in the POST request, including DICOMImageDetails
      const requestBody = {
        tenantId: tenantId,
        companyId: companyId,
        physicianId: physicianId,
        patientId: patientId,
        firstName: name,
        dob: dob,
        imageType: imageType,
        scanType: scanType,
        filePath: filePath,
        imageDescription: imageDescription,
        bodySite: bodySite,
        ...DICOMImageDetails,  // Spread DICOMImageDetails into the request body
        imageType: imageType,
      };

      console.log(requestBody);

      // Make the POST request to the API
      ApiCalls.register("saveImages", requestBody)
        .then((response) => {
          console.log(response);
          if (response.data.status.message === "Success") {
            if (response.data.status.details === "Patient Registered and Image Uploaded Successfully") {
              setSavedPatientId(response.data.status.uuID);
              setOpenPhysicianList(true);
            } else if (response.data.status.details === "Patient Image Uploaded Successfully") {
              swal(`${secureSessionStorage.getItem("PatientName")} Image Uploaded Successfully`, {
                icon: "success",
              });
              handleCancel();
              loadAllPatientImages();
            }
          } else if (response.data.status.message === "Error") {
            if (response.data.status.details === "PatientID already exists for a different patient. Please use a unique Patient ID.") {
              swal(`${secureSessionStorage.getItem("PatientName")} ID already exists for a different ${secureSessionStorage.getItem("PatientName")}. Please use a unique ${secureSessionStorage.getItem("PatientName")} ID`, {
                icon: "warning",
              });
            } else {
              console.log(`${secureSessionStorage.getItem("PatientName")} Image Upload Failed`);
            }
          }
        }).catch((err) => {
          console.log(err);
        })
    }
  };

  const handleCancel = () => {
    // Implement cancel functionality here
    setOpenDialog(false);
    setSelectedFile(null);
    setName('');
    setPatientId('');
    setDob('');
    setGender('');
    setPatientIdError(false);
    setNameError(false);
    setDobError(false);
    setImageTypeError(false);
    setFilePathError(false);
    setFileErrorMessage('');
  };

  const loadAllPatientImages = () => {
    setLoading(true);
    let tenantId = secureSessionStorage.getItem("tenantId");
    let companyId = secureSessionStorage.getItem("compId");
    let physicianId = "";
    const rolename = secureSessionStorage.getItem("rolename");

    if (rolename === "Tenant") {
      tenantId = secureSessionStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("UUID");
      physicianId = "0";
    } else if (rolename === "Physician") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("compId");
      physicianId = secureSessionStorage.getItem("UUID");
    }

    ApiCalls.getdetails(`tenant/${tenantId}/company/${companyId}/physician/${physicianId}/displayAllUploadedImages`)
      .then((response) => {
        console.log("API response:", response.data); // Check data structure
        if (response.data.diagnosticImage) {
          // Ensure the correct field is being used
          setPatients(response.data.diagnosticImage);
          console.log("Patient data set:", response.data.diagnosticImage); // Ensure data is set
        } else {
          setPatients([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("API call error:", error);
        setLoading(false);
        setPatients([]);
      });
  };

  const getPhysicianList = () => {
    var rolename = secureSessionStorage.getItem("rolename");
    var tenantId = "";
    var companyId = "";
    if (rolename === "Tenant") {
      tenantId = secureSessionStorage.getItem("UUID");
    }
    if (rolename === "Company") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("UUID");
    }
    ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians")
      .then((response) => {
        console.log("Physician Details");
        console.log("***********************************");
        console.log(response.data);
        if (response.data != undefined) {
          setPhysicians(response.data.physicians)
        } else {
          setPhysicians([]);
        }
      })
  }

  useEffect(() => {
    loadAllPatientImages();
    getPhysicianList();
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleButtonClicks = (patient) => {
    setSelectedPatient(patient);
    setOpenDialog(true);
  };

  const handleButton = (patient) => { };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPatient(null);
  };

  const handleButtonClick = (patient) => {
    setSelectedPatient(patient);
    setOpenDialogs(true);
  };

  const handleCloseDialogs = () => {
    setOpenDialogs(false);
    setSelectedPatient(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      setFileErrorMessage('');

      if (!imageType) {
        setError(true)
        setErrorMessage('Please select an image type.');
        return;
      }

      // Validate file type for DICOM
      if (imageType === "DICOM" && !file.name.toLowerCase().match(/\.(dcm|dicom)$/)) {
        setFileError(true);
        setFileErrorMessage('Only DICOM files (.dcm, .dicom) are allowed for DICOM image type.');
        setSelectedFile(null);
        return;
      }

      let tenantId = secureSessionStorage.getItem("tenantId");
      let companyId = secureSessionStorage.getItem("compId");
      let physicianId = "";
      const rolename = secureSessionStorage.getItem("rolename");

      if (rolename === "Tenant") {
        tenantId = secureSessionStorage.getItem("UUID");
        companyId = "0";
        physicianId = "0";
      } else if (rolename === "Company") {
        tenantId = secureSessionStorage.getItem("tenantId");
        companyId = secureSessionStorage.getItem("UUID");
        physicianId = "0";
      } else if (rolename === "Physician") {
        tenantId = secureSessionStorage.getItem("tenantId");
        companyId = secureSessionStorage.getItem("compId");
        physicianId = secureSessionStorage.getItem("UUID");
      }

      setFileLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('imageType', imageType);

      ApiCalls.storeFile("uploadFileToServerFromApp", formData)
        .then(response => {
          const { status } = response.data;
          if (status.message === "Success") {
            const filePath = status.filePath;
            setFilePath(filePath);
            let viewPath = imageType === 'Standard' ? 'viewFile' : 'viewDicomImage';

            ApiCalls.viewFile(viewPath, { filePath }, { responseType: 'blob' })
              .then(response => {
                const blob = new Blob([response.data], { type: 'image/png' });
                const url = window.URL.createObjectURL(blob);
                setImage(url);
                setSelectedFile(file);
                setFileLoading(false);

                if (imageType === "DICOM") {
                  readDicomDetails(filePath);
                }
              })
              .catch(error => {
                console.error(`Error in ${viewPath} API:`, error);
                setFileLoading(false);
                setError('Failed to view the uploaded file.');
              });
          } else {
            handleFileUploadError(status.details);
          }
        })
        .catch(error => {
          console.error('Error uploading image:', error);
          setFileLoading(false);
          setError('Error uploading image.');
        });
    }
  };

  const handleFileUploadError = (details) => {
    setFileLoading(false);
    if (details === "File already exists") {
      swal("File Already Exists", { icon: "info" });
    } else {
      console.error('Upload failed:', details);
      setError('Upload failed: ' + details);
    }
  };

  const readDicomDetails = (filePath) => {
    ApiCalls.getdetails(`readdicom?filePath=${encodeURIComponent(filePath)}`)
      .then(response => {
        setPatientId(response.data.patientId || '');
        setName(response.data.firstName || response.data.patientId || '');
        const dobDateFormatted = response.data.dobDate ? moment(response.data.dobDate).format("YYYY-MM-DD") : '';
        setDob(dobDateFormatted);
        setGender(response.data.gender || '');
        setScanType(response.data.scanType || '');
        setImageDescription(response.data.imageDescription || '');
        setBodySite(response.data.bodySite || '');

        // Explicitly set imageType to "DICOM"
        setImageType("DICOM");

        // Set remaining DICOM details
        const { patientId, firstName, dobDate, gender, scanType, imageDescription, bodySite, ...otherDetails } = response.data;
        setDICOMImageDetails(otherDetails);
      })
      .catch(error => {
        console.error('Error reading DICOM details:', error);
        setError('Error reading DICOM details.');
      });
  };

  useEffect(() => {
    return () => {
      if (image) {
        window.URL.revokeObjectURL(image);
      }
    };
  }, [image]);

  const handleImageTypeChange = (event) => {
    const newImageType = event.target.value;
    setImageType(newImageType);

    // Clear all errors when switching image type
    setError(false); // Clear generic error
    setErrorMessage('');
    setFileError(false); // Clear DICOM file-specific error
    setFileErrorMessage(''); // Clear DICOM file error message
    setPatientIdError(false);
    setNameError(false);
    setDobError(false);
    setDobErrorMessage('');
    setFilePathError(false);

    // Reset selected file and image if needed
    setSelectedFile(null);
    setImage(null);
    setFilePath('');
  };


  const handleUploadClick = () => {
    setOpenDialog(true);
  };

  const handleClosePhysicianList = () => {
    setOpenPhysicianList(false);
    // Implement cancel functionality here
    setOpenDialog(false);
    setSelectedFile(null);
    setName('');
    setPatientId('');
    setDob('');
    setGender('');
    setPatientIdError(false);
    setNameError(false);
    setDobError(false);
    setImageTypeError(false);
    setFilePathError(false)
  }

  const handleListItemClick = (event, physician) => {
    const checkbox = event.target.closest('input[type="checkbox"]');
    if (!checkbox) {
      const selectedIndex = selectedPhysicians.findIndex((p) => p.physicianuuid === physician.physicianuuid);
      if (selectedIndex === -1) {
        setSelectedPhysicians([...selectedPhysicians, physician]);
      } else {
        setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
      }
    }
  };

  const handleCheckboxChange = (event, physician) => {
    if (event.target.checked) {
      setSelectedPhysicians([...selectedPhysicians, physician]);
    } else {
      setSelectedPhysicians(selectedPhysicians.filter((p) => p.physicianuuid !== physician.physicianuuid));
    }
  };

  const handleAssignPhysician = () => {
    console.log(selectedPhysicians);
    var tenantId = secureSessionStorage.getItem("tenantId");

    var a = [];
    var list = selectedPhysicians.length;

    for (var i = 0; i < list; i++) {
      var counters = selectedPhysicians[i];
      var physicianuuid = counters.physicianuuid;
      var s = { "physicianuuid": physicianuuid }
      a.push(s);
    }

    var rolename = secureSessionStorage.getItem("rolename");
    var companyUserId = secureSessionStorage.getItem("userid")

    var data = {
      tenantId: tenantId,
      companyId: rolename === "Company" ? secureSessionStorage.getItem("UUID") : "0",
      physicians: a,
      patientuuid: savePatientId,
    };

    console.log("Final Data");
    console.log(data)
    ApiCalls.register(`assignphysicians?companyUserId=${companyUserId}`, data)
      .then((response) => {
        console.log(response.data);
        if (response.data.status.message === "Success") {
          swal(secureSessionStorage.getItem("PhysicianName") + " Successfully Assigned", {
            icon: "success",
          });
          handleClosePhysicianList();
          handleCancel();
          loadAllPatientImages();
        } else if (response.data.status.details === "Patient Already Registered") {
          swal(secureSessionStorage.getItem("PatientName") + " Already Registered", {
            icon: "info",
          });
        }
      }).catch((err) => {
        console.log(err)
      })

  }

  const filteredPatients = patients.filter((patient) => {
    const { date, imageType, scanType } = filters;
    const patientDate = moment(patient.scanDate).startOf('day');
    const filterDate = date ? moment(date).startOf('day') : null;

    return (
      (!filterDate || patientDate.isSame(filterDate)) &&
      (!imageType || patient.imageType.toLowerCase() === imageType.toLowerCase()) &&
      (!scanType || patient.scanType === scanType)
    );
  });


  return (
    <div style={{ display: "flex" }}>
      <Preview />
      <Container sx={{ marginTop: 10 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard">
              <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                Home
              </Button>
            </Link>


            <StyledBreadcrumb label="Manage Diagnostics" />
          </Breadcrumbs>
          <Button variant="contained" startIcon={<Iconify icon="material-symbols:upload" />} onMouseDown={handleMouseDown} onClick={handleUploadClick} >
            Upload Image
          </Button>
        </Stack>

        <Card>
          <CardHeader
            title={<Typography variant="h4" gutterBottom>
              {"Image List"}
            </Typography>}
          />
          <CardContent>
            {
              loading ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (filteredPatients.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>{secureSessionStorage.getItem("PatientName")} Id</TableCell>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>{secureSessionStorage.getItem("PatientName")} Name</TableCell>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Date and Time</TableCell>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Image Type</TableCell>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Scan Type</TableCell>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Image Description</TableCell>
                        <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>Body Part Scan</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPatients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((patient, index) => (
                        <TableRow key={patient.imageId} sx={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : 'inherit' }}>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {patient.patientId ? patient.patientId : patient.patientuuid}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {patient.firstName ? patient.firstName : "--"}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{moment(patient.scanDate).format('DD-MM-YYYY HH:mm') || 'N/A'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{patient.imageType || 'N/A'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{patient.scanType || 'N/A'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{patient.imageDescription || 'N/A'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{patient.bodySite || 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    sx={{
                      '.MuiButtonBase-root': {
                        '&:focus': {
                          outline: 'none',
                        },
                        '&:active': {
                          outline: 'none',
                          boxShadow: 'none',
                        },
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      },
                    }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredPatients.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              ) : (
                <center>
                  No Details Found
                </center>
              ))
            }
          </CardContent>
        </Card>

        <Dialog
          fullScreen={fullScreen}
          maxWidth="md"
          open={openDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Image Details"}</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
              <Paper elevation={3} style={{ padding: 20, maxWidth: '800px', width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Image Type
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      value={imageType}
                      onChange={handleImageTypeChange}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <div>
                        <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
                        <FormControlLabel value="DICOM" control={<Radio />} label="DICOM" />
                      </div>
                    </RadioGroup>
                    {imageTypeError && <Typography color="error" variant="body2">{errorMessage}</Typography>}

                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                      name="patientId"
                      label={
                        <span>
                          {secureSessionStorage.getItem("PatientName") + " Id"}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      value={patientId}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { height: '50px' },
                        readOnly: imageType === 'DICOM'
                      }}
                      style={{ marginBottom: '22px' }}
                      error={patientIdError}
                      helperText={patientIdError && `${secureSessionStorage.getItem("PatientName")} Id is required`}
                    />
                    <TextField
                      name="name"
                      label={
                        <span>
                          {secureSessionStorage.getItem("PatientName") + " Name"}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      value={name}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        style: { height: '50px' },
                        readOnly: imageType === 'DICOM'
                      }}
                      style={{ marginBottom: '22px' }}
                      error={nameError}
                      helperText={nameError && `${secureSessionStorage.getItem("PatientName")} Name is required`}
                    />
                    <TextField
                      id="date"
                      name="dob"
                      label={
                        <span>
                          DOB{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      type="date"
                      value={dob}
                      onChange={handleInputChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: maxDate,
                      }}
                      error={dobError}
                      helperText={dobError && dobErrorMessage}
                    />
                    <FormControl variant="outlined" fullWidth sx={{ marginBottom: '22px', mt: 2 }}>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        name="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        label="Gender"
                        inputProps={{
                          readOnly: imageType === 'DICOM',  // Make read-only if image type is DICOM
                        }}
                      >
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    {fileLoading ? (
                      <Avatar
                        alt="Image"
                        variant="rounded"
                        sx={{
                          width: 350,
                          height: 300,
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            cursor: 'pointer',
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        <Icon icon="eos-icons:bubble-loading" color="#4481eb" width="80" />
                      </Avatar>
                    ) : (
                      selectedFile && imageType === 'DICOM' ? (
                        <DicomViewer file={selectedFile} />
                      ) : (
                        <Avatar
                          alt="Image"
                          variant="rounded"
                          src={selectedFile ? URL.createObjectURL(selectedFile) : null}
                          sx={{
                            width: 350,
                            height: 300,
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                              cursor: 'pointer',
                              transform: 'scale(1.1)',
                            },
                          }}
                          onClick={() => document.getElementById('avatar-input').click()}
                        >
                          <Icon icon="flat-color-icons:add-image" color="#4481eb" width="80" />
                          <input
                            type="file"
                            accept=".dcm, .dicom"
                            id="avatar-input"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                        </Avatar>
                      )
                    )}

                    {/* Display File Error Message */}
                    {fileError && (
                      <Typography
                        variant="subtitle2"
                        color="error"
                        style={{
                          marginTop: 10,
                          textAlign: 'center',
                        }}
                      >
                        {fileErrorMessage}
                      </Typography>
                    )}

                    {/* Error Message */}
                    {error && (
                      <Typography
                        variant="subtitle2"
                        color="error"
                        style={{
                          marginTop: 10,
                          textAlign: 'center',
                        }}
                      >
                        {error}
                      </Typography>
                    )}
                  </Grid>

                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleSaveDetails}
                    >
                      Save Details
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openPhysicianList}
          onClose={handleClosePhysicianList}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title">
          <Card>
            <CardHeader
              title={"Select " + secureSessionStorage.getItem("PhysicianName")}
              action={
                <IconButton color="primary" onMouseDown={handleMouseDown} onClick={handleClosePhysicianList}>
                  <Close />
                </IconButton>
              }
            />
            <CardContent sx={{ maxHeight: 350, overflowY: "scroll" }}>
              <List sx={{ pt: 0 }}>
                {physicians.length > 0 ?
                  physicians.map((physician) => (
                    <ListItem disableGutters>

                      <ListItemButton onClick={(event) => handleListItemClick(event, physician)}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedPhysicians.some((p) => p.physicianuuid === physician.physicianuuid)}
                            onChange={(event) => handleCheckboxChange(event, physician)}
                          />
                        </ListItemIcon>
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: blue[100], color: blue[600], width: 50, height: 50 }} src={physician.image} />
                        </ListItemAvatar>
                        <ListItemText primary={physician.firstName + " " + physician.lastName} />
                      </ListItemButton>

                    </ListItem>
                  )) : <Typography variant="body1">No {secureSessionStorage.getItem("PhysicianName")} available</Typography>

                }
              </List>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="error"
                size="small"
                fullWidth
                startIcon={<Close />}
                onClick={handleClosePhysicianList}
              >Cancel
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                startIcon={<Done />}
                onClick={handleAssignPhysician}
              >Submit
              </Button>
            </CardActions>
          </Card>
        </Dialog>

      </Container>
    </div>
  );
};

export default ManageDiagnostics;