import React, { useEffect, useRef, useState } from 'react';
import EcgChart from './EcgChart';
import LiveLineChart from './LiveLineChart';
import PpgChart from './PpgChart';
// import { useParams } from 'react-router-dom';


const GraphNew = () => {
    // const maxDataPoints = 125;
    // let { deviceid, patient } = useParams();
    const [deviceID, setDeviceID] = useState('');
    const [patientThreshold, setPatientThreshold] = useState({});
    const timeoutRef = useRef(null);
    const wsRef = useRef(null);
    const [reconnect, setReconnect] = useState(false); // State to control reconnections
    const [hasReconnected, setHasReconnected] = useState(false);

    const [ppg, setPpg] = useState(Array(620));
    const [ecg, setEcg] = useState(Array(1250));
    const [rrw, setRrw] = useState(Array(620));
    const [index, setIndex] = useState(0);
    // const [params, setParams] = useState({});
    const [paramValues, setParamValues] = useState({

    })

    // Initial Working code
    // New working code with more conditions and Origin
    useEffect(() => {
        const handleMessage = (event) => {
            console.log('Message received:', event);
            console.log('Message origin:', event.origin);
            console.log('Message data:', event.data);

            // Ensure the message is coming from the expected origin
            if (event.type === "message" &&
                (event.origin === "" ||
                    event.origin === 'http://10.0.2.2:3000' ||
                    event.origin === 'http://localhost:3000' ||
                    event.origin === 'https://dev.iorbit.health' ||
                    event.origin === 'https://itouch-health.iorbit.health')) {
                let data;
                if (typeof event.data === 'string') {
                    if (event.data) {
                        try {
                            data = JSON.parse(event.data);
                        } catch (e) {
                            console.error('Invalid JSON:', e);
                            return;
                        }
                    } else {
                        console.warn('Empty string received');
                        return;
                    }
                } else {
                    data = event.data;
                }

                if (data) {
                    try {
                        const extractedData = JSON.parse(JSON.stringify(data));
                        const deviceId = extractedData?.deviceid;
                        const stringPatient = extractedData?.stringPatient ? decodeURIComponent(extractedData.stringPatient) : '';

                        // Important alerts for testing
                        // alert("deviceId: " + deviceId);
                        // alert("stringPatient: " + stringPatient);

                        if (deviceId) {
                            setDeviceID(deviceId);
                        } else {
                            console.warn('deviceId is undefined');
                        }

                        if (stringPatient) {
                            setPatientThreshold(JSON.parse(stringPatient));
                        } else {
                            console.warn('stringPatient is undefined or empty');
                        }
                    } catch (e) {
                        console.error('Error processing data:', e);
                    }
                } else {
                    console.warn('Data is undefined');
                }
            } else {
                console.warn('Unexpected message origin:', event.origin);
            }
        };

        window.addEventListener('message', handleMessage, true);

        return () => {
            window.removeEventListener('message', handleMessage, true);
        };
    }, []);
    // New working code with more conditions and Origin

    const resetTimeout = () => {
        clearTimeout(timeoutRef.current);

        // Set timeout to detect if no data is received for 1 minute
        timeoutRef.current = setTimeout(() => {
            console.log('No data received for 10 seconds');
            setReconnect(false);
            setHasReconnected(false);
            clearall();
        }, 10000); // = 10 secs
    };

    const clearall = () => {
        setPpg(Array(620));
        setEcg(Array(1250));
        setRrw(Array(620));
    }

    useEffect(() => {
        setSlide([
            {
                min: parseFloat(patientThreshold ? patientThreshold.hrmin : 30), max: parseFloat(patientThreshold ? patientThreshold.hrmax : 295)
            },
            {
                min: parseFloat(patientThreshold ? patientThreshold.spo2min : 70), max: parseFloat(patientThreshold ? patientThreshold.spo2max : 100)
            },
            {
                min: parseFloat(patientThreshold ? patientThreshold.rrmin : 8), max: parseFloat(patientThreshold ? patientThreshold.rrmax : 35)
            }
        ]);
    }, [patientThreshold])


    useEffect(() => {
        let ws = wsRef.current = new WebSocket("wss://itouch-test.iorbit.health:8083/consumer"); //TEST
        ws.onopen = () => {
            console.log('WebSocket connection opened', deviceID);
            ws.send(JSON.stringify(
                {
                    "PacketID": "WS_DEVICE_CONSUME_REQ",
                    "DeviceUUID": deviceID,
                    // "DeviceUUID": deviceId,
                    // "DeviceUUID": "f63324200444899d",
                    "SamplingRate": 250,
                    "WindowSize": 10,
                    "MinYVal": 0,
                    "MaxYVal": 128
                }
            ));
            resetTimeout();
        };


        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onmessage = async (e) => {
            // const message = event.data;
            const message = JSON.parse(e.data);
            console.log(message, "messaged");
            const { doubleArray, ParamUUID } = await processMessage(message);
            console.log(doubleArray, ParamUUID, "newdatafound");
            handleEachArray(doubleArray, ParamUUID)
            resetTimeout();
        };
        //reconnecting code
        ws.onclose = () => {
            console.log("WebSocket closed, attempting to reconnect in 2 seconds...");
            if (!hasReconnected) {
                setReconnect(true);
            }
        };
        //reconnecting code
        const handleEachArray = (array, id) => {
            if (id === '10000') setEcg(array)
            if (id === '10002') setPpg(array)
            if (id === '10004') setRrw(array)
        }
        return () => {
            wsRef.current.close();
            clearTimeout(timeoutRef.current);
        };
    }, [deviceID, reconnect]);

    //reconnecting code
    useEffect(() => {
        if (reconnect && !hasReconnected) { // Proceed only if not already reconnected
            const reconnectTimeout = setTimeout(() => {
                console.log("Reconnecting WebSocket...");
                setReconnect(false); // Reset reconnection state
                setHasReconnected(true); // Mark as reconnected after first attempt
            }, 2000); // Reconnect after 2 seconds

            return () => clearTimeout(reconnectTimeout);
        }
    }, [reconnect, hasReconnected]);
    //reconnecting code

    const processMessage = async (msgString, parameterId) => {
        console.log(msgString, 'msgstringgot');
        let ParamUUID = ''
        let doubleArray = null;
        let paramValues = [];
        try {
            const commandParams = msgString;
            const command = commandParams.PacketID;
            console.log(commandParams.PacketID);

            switch (command) {
                case "WS_DEVICE_INFO":
                    // Handle WS_DEVICE_INFO case if needed
                    break;

                case "WS_PARAM_INFO":
                    // Handle WS_PARAM_INFO case if needed
                    break;

                case "WS_PARAM_DATA":
                    const paramUUID = commandParams.ParamUUID;
                    const paramType = commandParams.ParamType;
                    // const seqNum = commandParams["SNo"];

                    const dataType = commandParams.hasOwnProperty("DataType")
                        ? commandParams["DataType"]
                        : "Double";

                    // console.log(paramUUID, parameterId);

                    if (paramType === "Stream") {
                        ParamUUID = commandParams.ParamUUID === undefined ? 10004 : commandParams.ParamUUID
                        console.log('Stream', typeof (ParamUUID), paramUUID);
                        // if (ParamUUID === '10002') {
                        const data = commandParams["Data"];
                        if (dataType === "Double") {
                            console.log('itsDoublesss');
                            doubleArray = await convertFromBase64StringToDouble(data);
                        } else {
                            console.log('itsDouble');
                            doubleArray = await convertBase64ComaSeparatedStringToDoubleArray(data);

                        }
                        // }
                    } else {
                        const paramValue = commandParams.Value;
                        console.log("paramValues: " + paramValue);
                        const valD = parseFloat(paramValue);
                        // let alarmBorderColor = "transparent";
                        // console.log("valId: " + valD);
                        paramValues[paramUUID] = valD;
                        console.log(paramValues, "paramValues test");

                        setParamValues((paramValues) => ({
                            ...paramValues,
                            [paramUUID ? paramUUID : '10005']: {
                                paramUUID,
                                paramName: 'SpO₂',
                                value: valD,
                                // alarmBorderColor: newAlarmBorderColor
                            }
                        }));


                    }
                    break;

                default:
                    console.error("Unknown command:", command);
                    break;
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }

        return { doubleArray, ParamUUID };
    };
    const convertFromBase64StringToDouble = (message) => {
        try {
            const byteData = atob(message);
            const byteArray = new Uint8Array(byteData.length);
            for (let i = 0; i < byteData.length; i++) {
                byteArray[i] = byteData.charCodeAt(i);
            }

            const doubleArray = [];
            const dataView = new DataView(byteArray.buffer);

            // Ensure that there is enough data for at least one double
            if (byteArray.length >= 8) {
                for (let i = 0; i < byteArray.length; i += 8) {
                    // Check if there is enough data remaining for the current double
                    if (i + 8 <= byteArray.length) {
                        console.log(dataView.getFloat32(i, false), "dataat");
                        doubleArray.push(dataView.getFloat32(i, false)); // true for little-endian
                    } else {
                        // console.error("Not enough data for a double at offset", i);
                        // console.log("Current byteArray:", byteArray);
                        console.log("Current doubleArray:", doubleArray);
                        break;
                    }
                }
            } else {
                console.error("Not enough data for a double");
            }

            return doubleArray;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    };

    const convertBase64ComaSeparatedStringToDoubleArray = (message) => {
        try {
            const byteData = atob(message);
            const intStrings = byteData.split(",");
            const doubleArray = intStrings.map((str) => parseInt(str));
            return doubleArray;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [slide, setSlide] = useState([
        {
            min: parseFloat(patientThreshold ? patientThreshold.hrmin : 30), max: parseFloat(patientThreshold ? patientThreshold.hrmax : 295)
        },
        {
            min: parseFloat(patientThreshold ? patientThreshold.spo2min : 70), max: parseFloat(patientThreshold ? patientThreshold.spo2max : 100)
        },
        {
            min: parseFloat(patientThreshold ? patientThreshold.rrmin : 8), max: parseFloat(patientThreshold ? patientThreshold.rrmax : 35)
        }
    ]);
    console.log("slide in index", slide);
    const slideInfo = [
        {
            color: '#3BE041',
            range: {
                min: slide[0].min, max: slide[0].max
            },
            tag: 'Ecg',
            value: 'Ecg',
            unit: 'bpm'

        },
        {
            color: '#009FE3',
            range: {
                min: slide[1].min, max: slide[1].max
            },
            tag: 'SpO₂',
            value: 'SPO2',
            unit: '%'
        },
        {
            color: '#F2B809',
            range: {
                min: slide[2].min, max: slide[2].max
            },
            tag: 'RR',
            value: 'RR',
            unit: 'rpm'
        },
    ];


    console.log("Akshivparams", index);
    return (
        <div style={{ width: "100%", height: '100vh', backgroundColor: '#080f18' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '100vh' }}>
                    <EcgChart liveData={ecg} parameterName='ECG' parameterId={"10000"} />
                    <PpgChart liveData={ppg} parameterName='SPO2' parameterId={"10002"} />
                    <LiveLineChart liveData={rrw} parameterName='RR' parameterId={"10004"} />
                </div>
            </div>
        </div >
    )
}

export default GraphNew;